import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './AuthService';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '../_environments/environment';
import { GlobalService } from './GlobalService';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  env = environment;

  constructor(
    private authService: AuthService,
    private _globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = this.setCorrectTLD(req);

    if (req.url.endsWith('/auth/oauth/token')) {
      return next.handle(req);
    } else {
      req = this.updateHeader(req);

      return next.handle(req).pipe(
        catchError((error, caught) => {
          if (error instanceof HttpErrorResponse) {
            //makes sure a tokenrefresh is only done when it is expired, not when a resetpassword is failed
            if (this._checkTokenExpiryErr(error) && !req.url.endsWith('/user/changepassword/loggedin')) {
              return this.authService.doTokenRefresh().pipe(
                switchMap(() => {
                  return next.handle(this.updateHeader(req));
                }),
                catchError(() => {
                  // makes sure on token refresh errors are catched
                  return throwError(() => error);
                })
              );
            } else {
              return throwError(() => error);
            }
          }
          return caught;
        })
      );

    }

  }

  setCorrectTLD(req: HttpRequest<any>) {
    // TODO: WHY should we change calls to the API, endpoints should slway be the same !?
    // TODO: remove this, since it makes thing REALY complicated for no good reason
    // TODO: OR write down here WHY this is done, here...

    //doesnt have to do this on the server
    if (isPlatformServer(this.platformId)) return req;

    //replacing top level domain
    const domainregex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/im;
    const EMdomainregex = /\.[^.]+\.[^.]+\.ekomenu\.[^.]+/;
    const tldregex = /(\.\w+)$/img;

    //gets only the xxx.xxx.ekomenu.xxx calls
    let EMdomain
    const EMdomainRegexResult = RegExp(EMdomainregex).exec(req.url);
    if (EMdomainRegexResult)
      EMdomain = EMdomainRegexResult[0]
    //gets the domain origin of the call
    let domain = RegExp(domainregex).exec(EMdomain)[0]

    //gets the tld of the call
    let tld;
    const tldRegexResult = RegExp(tldregex).exec(domain);
    if (tldRegexResult) {
      tld = tldRegexResult[0];
    }

    //gets the tld of the browser
    // let origintld;
    // TOOD: this is way easier !?
    let origintld = location.hostname.split('.').slice(-1)[0];
    
    // const origin = isPlatformBrowser(this.platformId) ? location.origin : 'https://www.ekomenu.nl'; // TODO: how do we create pages for .be ?
    // const origintldRegexResult = RegExp(tldregex).exec(origin);
    // if (origintldRegexResult) {
    //   origintld = origintldRegexResult[0];
    // }

    //origintld = ".be"
    //if the tld of the call and the tld of the browser exist and are not the same, the tld of the call is replaced with the tld of the browser
    if (tld && origintld && ['.nl', '.be'].includes(origintld) && tld !== origintld) {
      console.log('no tld match....', tld, origintld)
      const replacedTld = domain.replace(RegExp(tldregex), origintld);
      req = req.clone({ url: req.url.replace(domain, replacedTld) })
    }

    return req;
  }

  updateHeader(req: HttpRequest<any>) {

    if (isPlatformBrowser(this.platformId)) {
      const authToken = JSON.parse(localStorage.getItem('token'));
      if (authToken !== null && !req.headers.has('Authorization')) {
        req = req.clone({
          setHeaders: { 'Authorization': 'Bearer ' + authToken.access_token }
        });
      }
    }
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        setHeaders: { 'Content-Type': 'application/json' }
      });
    }
    if (!req.headers.has('Accept')) {
      req = req.clone({
        setHeaders: { 'Accept': 'application/json' }
      });
    }

    if (!req.headers.has('brand')) {
      req = req.clone({
        setHeaders: { 'brand': String(this.env.brandid) }
      });
    }
    if (!req.headers.has('language')) {
      let lang = 'NL'
      if(this._globalService && this._globalService.getLanguage && typeof this._globalService.getLanguage === 'function'){
        lang = this._globalService.getLanguage().toUpperCase();
      }
      req = req.clone({
        setHeaders: { 'language': lang } //'NL' }
      });
    }


    return req;
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {

    return (
      error.status &&
      error.status === 401
    );
  }

}

