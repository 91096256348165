@if (!!imageonly.main_image?.filename) {
<div class="relative h-full">
    <img [alt]="get_alt(imageonly.main_image)" class="rounded-2xl h-full w-full" [src]="get_src(imageonly.main_image, null, null, imageWidth)" [attr.loading]="'lazy'" />

    @if(imageonly.title || imageonly.subtitle){
        <div class="absolute inset-0 rounded-2xl bg-gradient-to-b from-transparent to-[#3c4a34d9]"></div>
    }
   
    <div class="flex flex-col sm:flex-row justify-between w-full absolute bottom-0 p-3 gap-3 text-start">
        <div class="flex flex-col text-white">
            @if(imageonly.title){
            <div [innerHTML]="imageonlyTitle"></div>
            }
            @if(imageonly.subtitle){
            <span>{{imageonly.subtitle}}</span>
            }
        </div>
        @if(imageonly.CTA){
        <app-cms-button class="mt-auto" [button]="imageonly.CTA[0]"></app-cms-button>
        }
    </div>
</div>
}