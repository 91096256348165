
<ng-container *ngFor="let method of paymentmethodOrder">

  <ng-container *ngIf="includesPaymentmethod(method)" [ngSwitch]="method">
    <div *ngSwitchCase="'EMAIL'" id="emailpaymentmethod" class="paymentmethod max-w-[940px] mx-auto flex flex-col bg-white rounded-lg p-[25px] mt-[25px] md:mt-[50px]">
      <div class="flex flex-row items-center justify-between" (click)="paymentForm.get('paymentmethod').setValue('EMAIL')">
        <div class="flex flex-row items-center">
          <input type="radio" class="mr-[15px]" value="'EMAIL'" [checked]="paymentForm.get('paymentmethod').value === 'EMAIL'" />
          <h2 class="font-sans">{{'paymentmethod.EMAIL' |translate}}</h2>
        </div>
        <div class="flex flex-row">
          <i class="bi bi-envelope text-3xl mt-2"></i>
        </div>
      </div>
    </div>
    <ng-container *ngSwitchCase="'CREDITCARD'">
      <div *ngIf="_countrycode ==='BE'" id="creditcardpaymentmethod" class="paymentmethod max-w-[940px] mx-auto flex flex-col bg-white rounded-lg p-[25px] mt-[25px] md:mt-[50px]">
        <div class="flex flex-row items-center justify-between" (click)="paymentForm.get('paymentmethod').setValue('CREDITCARD')">
          <div class="flex flex-row items-center">
            <input type="radio" class="mr-[15px]" value="'CREDITCARD'" [checked]="paymentForm.get('paymentmethod').value === 'CREDITCARD'" />
            <h2 class="font-sans">{{'paymentmethod.CREDITCARD' |translate}}</h2>
          </div>
          <div class="flex flex-row">
            <i class="bi bi-credit-card-2-front text-3xl mt-2"></i>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2">
          <app-chip *ngFor="let network of creditcardnetworks; let i=index" colorscheme="contrast_with_border" (click)="paymentForm.get('creditcardnetwork').setValue(network); paymentForm.get('paymentmethod').setValue('CREDITCARD')" [selected]="paymentForm.get('creditcardnetwork').value === network">
            <div class="flex flex-row items-center">
              <span class="w-20 mr-[25px]">
                <img class="h-5" alt="{{'logo ' + network}}" src="./assets/images/creditcardnetworks/{{network |lowercase}}.svg" />
              </span>
              <span>{{'creditcardnetwork.' + network | translate}}</span>
            </div>
          </app-chip>
        </div>
      </div>
    </ng-container>

    <div *ngSwitchCase="'BANCONTACT'" id="bancontactpaymentmethod" class="paymentmethod max-w-[940px] mx-auto flex flex-col bg-white rounded-lg p-[25px] mt-[25px] md:mt-[50px]">
      <div class="flex flex-row items-center justify-between" (click)="paymentForm.get('paymentmethod').setValue('BANCONTACT')">
        <div class="flex flex-row items-center">
          <input type="radio" class="mr-[15px]" value="'BANCONTACT'" [checked]="paymentForm.get('paymentmethod').value === 'BANCONTACT'" />
          <h2 class="font-sans">{{'paymentmethod.BANCONTACT' |translate}}</h2>
        </div>
        <div class="flex flex-row">
          <img class="h-[25px]" src="./assets/images/banks/bancontact-logo.svg" />
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'IDEAL'" id="idealpaymentmethod" class="paymentmethod max-w-[940px] mx-auto flex flex-col bg-white rounded-lg p-[25px] mt-[25px] md:mt-[50px]">
      <div class="flex flex-row items-center justify-between" (click)="paymentForm.get('paymentmethod').setValue('IDEAL')">
        <div class="flex flex-row items-center">
          <input type="radio" class="mr-[15px]" value="'IDEAL'" [checked]="paymentForm.get('paymentmethod').value === 'IDEAL'" />
          <h2 class="font-sans">{{'paymentmethod.IDEAL' |translate}}</h2>
          <img class="ml-[25px] h-10" src="./assets/images/banks/ideal-logo.svg" />
        </div>
        <div class="flex flex-row">
          <small><img class="inline mr-2" src="./assets/images/lock.svg" />{{'checkout.pay_safely_online' | translate}}</small>
        </div>
      </div>
      <!-- <div class="row mt-4 mb-2">
        <small>
          <strong>{{'checkout.choose_your_bank' | translate}}</strong>
        </small>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <app-chip *ngFor="let bank of idealIssuers; let i=index" colorscheme="contrast_with_border" (click)="paymentForm.get('bic').setValue(bank); paymentForm.get('paymentmethod').setValue('IDEAL')" [selected]="paymentForm.get('bic').value === bank">
          <div class="flex flex-row items-center">
            <span class="w-20 mr-[25px]">
              <img class="h-5" alt="{{'logo ' + bank}}" src="./assets/images/banks/{{bank |lowercase}}.svg" />
            </span>
            <span>{{'bank.' + bank | translate}}</span>
          </div>
        </app-chip>
      </div> -->
    </div>
    <div *ngSwitchCase="'NONE'" id="emailpaymentmethod" class="paymentmethod max-w-[940px] mx-auto flex flex-col bg-white rounded-lg p-[25px] mt-[25px] md:mt-[50px]">
      <div class="flex flex-row items-center justify-between" (click)="paymentForm.get('paymentmethod').setValue('NONE')">
        <div class="flex flex-row items-center">
          <input type="radio" class="mr-[15px]" value="'NONE'" [checked]="paymentForm.get('paymentmethod').value === 'NONE'" />
          <h2 class="font-sans">{{'paymentmethod.NONE' |translate}}</h2>
        </div>
        <div class="flex flex-row">
          <i class="text-xl bi bi-currency-euro"></i>
        </div>
      </div>
      <div class="flex flex-row">
        <span>{{'checkout.other_iban_method_contact_customersupport' |translate}}</span>
      </div>
    </div>
  </ng-container>
</ng-container>


