import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Stack } from 'src/_model/storyblock';
import { CmsImageOnlyComponent } from "../cms-image-only/cms-image-only.component";

@Component({
  selector: 'app-cms-stack',
  standalone: true,
  imports: [NgClass, CmsImageOnlyComponent],
  templateUrl: './cms-stack.component.html',
  styleUrl: './cms-stack.component.scss'
})
export class CmsStackComponent {

  private _stack: Stack
  @Input() set stack(value:Stack){
    this._stack=value;
  }

  get stack(){
    return this._stack
  }

  constructor(){

  }

}
