import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-cms-button',
    templateUrl: './cms-button.component.html',
    standalone: true,
    imports: [NgIf, NgClass]
})
export class CmsButtonComponent {

  private _button:any;
  @Input() set button (value: any){
    this._button = value;
    if(value.title)
      this.renderedTitle = this._cmsService.render_rt(value.title);
  }
  get button(){return this._button}

  @Input() removeMargin: boolean = false
  renderedTitle: any;

  constructor(private _cmsService: CmsService) {
  }


  get_content(content) {
    return this._cmsService.get_content(content);
  }

  get_class(type) {    
    switch (type) {
      case 'PLAIN':
        return 'text-e-light-green underline'
      case 'SMALL':
        return 'button-small bg-e-dark-green'
      case 'BIG':
        return `button-big inline-block pt-[20px]`
      case 'BIG_DARKGREEN':
        return 'button-card bg-e-dark-green text-e-reg-green text-left min-w-[400px]'
      case 'ORANGE':
        return 'button-base flex rounded-lg bg-e-orange text-white hover:bg-e-light-green hover:text-e-dark-green'
      default:
        return `button-base rounded-full inline-block  w-full sm:w-auto mx-auto ${this.removeMargin? '': 'mt-4 md:mr-4'}`
    }

  }

  get_href(url_field) {
    // make all urls relative inside out domains
    let href = url_field.cached_url.replace(/https:\/\/(www\.)?ekomenu\.(nl|be)/, '');
    // try the correct translated slug if available
    if( url_field.linktype == 'story' && !!url_field.story && !!url_field.story.translated_slugs) {
      try {
        const trans_slug = url_field.story.translated_slugs.find(s => s.lang == url_field.story.lang);
        // href = `/${trans_slug.lang}/${trans_slug.path}`;
        href = `/${trans_slug.path}`; // since the pref lang is persistent we can omiit the lang in the href
      } catch (error) {
        // console.warn(error);
      }
    }
    if (url_field.linktype === 'email') {
      href = 'mailto:' + href;
    }
    if (url_field.anchor) {
      href += '#' + url_field.anchor
    }
    return href;
    // TODO: move this logic to the service
    // return this._cmsService.get_href(url_field);
  }

  //when used this in the template the content gets other keys constantly
  // render_rt(content) {
  //   return this._cmsService.render_rt(content);
  // }
  
  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}
  get_classes(card) {
    return this._cmsService.get_classes(card);
  }

}



