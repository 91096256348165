<div class="flex p-6 gap-6 rounded-2xl" 
[ngClass]="[stack.flip_layout?'flex-col-reverse md:flex-row-reverse': 'flex-col md:flex-row', 'bg-'+ stack.bgcolor]">
    <div class="" [ngClass]="stack.layout === 'fiftyfifty' ? 'md:w-1/2' : 'flex-grow md:w-2/3'">
        <app-cms-image-only [imageonly]="stack.single_block[0]"></app-cms-image-only>
    </div>
    <div 
    class="flex flex-col gap-6 my-auto"
    [ngClass]="stack.layout === 'fiftyfifty' ? 'md:w-1/2' : 'flex-grow md:w-1/3'"
    >
        <div><app-cms-image-only [imageonly]="stack.stack_blocks[0]"></app-cms-image-only></div>
        <div><app-cms-image-only [imageonly]="stack.stack_blocks[1]"></app-cms-image-only></div>
    </div>

</div>
<!-- background: linear-gradient(357.52deg, rgba(60, 74, 52, 0.85) 1.78%, rgba(0, 0, 0, 0) 54.83%); -->
