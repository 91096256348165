import { MediaMatcher } from '@angular/cdk/layout';
import { NgClass, NgFor, NgIf, NgTemplateOutlet, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnChanges, PLATFORM_ID, ViewChild } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { Swiper, SwiperOptions } from 'swiper/types';
import { CmsButtonComponent } from '../cms-button/cms-button.component';
import { CmsCardComponent } from '../cms-card/cms-card.component';
import { Slide, Slider } from 'src/_model/storyblock';

@Component({
  selector: 'app-cms-swiper',
  templateUrl: './cms-swiper.component.html',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgIf, NgClass, NgFor, NgTemplateOutlet, CmsCardComponent, CmsButtonComponent]
})

export class CmsSwiperComponent implements AfterViewInit, OnChanges {

  @ViewChild('swiperRef') private swiperRef;
  swiper?: Swiper;

  private _slider: Slider
  @Input() set slider(value: Slider) {
    this._slider = value;
    this._slider.slides.forEach(slide=>{
      if(slide.title)
        slide['title_rendered']= this._cmsService.render_rt(slide.title)      
      
      //for backwards compatibility from storyblock -> ability to move to storyblock color variable
      if(slide.bg_color.startsWith('bg-'))
          slide.bg_color = slide.bg_color.replace('bg-', '')

    })
  };
  get slider() { return this._slider }

  isMobile: any;
  fixedBlock: Slide;

  options: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      clickable: true,
      bulletClass: 'swiper-pagination-bullet '
    },
    breakpoints: {
      1024: {
        slidesPerView: 1,
        pagination: {
          clickable: false,
          bulletClass: 'swiper-pagination-bullets hidden'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },
    enabled: true,
    direction: 'horizontal',
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.resize();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private elementRef: ElementRef,
    private media: MediaMatcher,
    private _cmsService: CmsService,
    private ref: ChangeDetectorRef) {

    this.isMobile = media.matchMedia('(max-width: 960px)').matches;
  }

  get_content(field) {
    return this._cmsService.get_content(field);
  }

  get_src_as_css(field, contain) {
    return this._cmsService.get_src_as_css(field, contain)
  }

  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  resize() {
    this.isMobile = this.media.matchMedia('(max-width: 960px)').matches;
  }

  expandedSlidesCount() {
    let count = 1;
    if (this.slider.expand_on_desktop && parseInt(this.slider?.no_of_slides) > 1) {
      count = parseInt(this.slider.no_of_slides);
    } else if (this.slider.expand_on_desktop) {
      count = this.slider.slides.length;
    } else if(this.slider.slide_type === 'banner') {
      count=1.2;
    }else{
      count = 1;
    }
    return count;
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;

    this.swiperRef;
    const swiperEl = this.swiperRef.nativeElement;
    if(this.slider.slide_type === 'banner'){
      this.options.loop = true;
      this.options.centeredSlides = true;
      this.options.breakpoints[1024].loop = true;
      this.options.breakpoints[1024].spaceBetween = 40;
      this.options.breakpoints[1024].centeredSlides = true;

    }
    this.options.breakpoints[1024].slidesPerView = this.expandedSlidesCount();
    Object.assign(swiperEl, this.options);

    this.swiper = this.swiperRef.nativeElement.swiper;
    swiperEl.initialize();
  }


  ngOnChanges() {
    if (this.slider.fixed) {
      this.fixedBlock = this.slider.slides[0];
      if (this.fixedBlock.title)
        this.fixedBlock["title_rendered"] = this._cmsService.render_rt(this.fixedBlock.title)
      this.slider.slides.shift();
    }

  }



}
