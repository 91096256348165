import { NgIf, NgTemplateOutlet, NgFor, NgClass, CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterOutlet, RouterLink } from '@angular/router';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Box } from '../../_model/box';
import { Flowtype, Orderline } from '../../_model/order';
import { Product } from '../../_model/product';
import { Entitytype } from '../../_model/redirect';
import { TaxonomyNode } from '../../_model/taxonomy';
import { DrawerService, Drawerposition } from '../../_service/DrawerService';
import { GlobalService } from '../../_service/GlobalService';
import { OrderService } from '../../_service/OrderService';
import { RedirectService } from '../../_service/RedirectService';
import { TaxonomyService } from '../../_service/TaxonomyService';
import { ProductService } from '../../_service/ProductService';
import { BoxService } from '../../_service/BoxService';
import { Countrycode } from '../../_model/shared';
import { TranslateModule } from '@ngx-translate/core';
import { PricetotalComponent } from '../../sharedModule/pricetotal/pricetotal.component';
import { EmptylineComponent } from '../../sharedModule/orderline/emptyline/emptyline.component';
import { BoxlineComponent } from '../../sharedModule/orderline/boxline/boxline.component';
import { ProductlineComponent } from '../../sharedModule/orderline/productline/productline.component';
import { BoxComponent } from '../../sharedModule/orderline/box/box.component';
import { ProductComponent } from '../../sharedModule/orderline/product/product.component';
import { BoxcardComponent } from '../../sharedModule/orderline/boxcard/boxcard.component';
import { ProductcardComponent } from '../../sharedModule/orderline/productcard/productcard.component';
import { TaxonomynodeComponent } from '../../sharedModule/taxonomynode/taxonomynode.component';
import { LinkService } from '../../_service/LinkService';


@Component({
    selector: 'app-webshop',
    templateUrl: './webshop.component.html',
    styleUrls: ['./webshop.component.scss'],
    standalone: true,
    imports: [NgIf, TaxonomynodeComponent, ProductcardComponent, BoxcardComponent, NgTemplateOutlet, RouterOutlet, ProductComponent, BoxComponent, ReactiveFormsModule, NgFor, NgClass, ProductlineComponent, BoxlineComponent, EmptylineComponent, PricetotalComponent, RouterLink, CurrencyPipe, TranslateModule]
})
export class WebshopComponent implements OnInit, OnDestroy {
  constructor(
    public activatedRoute: ActivatedRoute,
    private _orderService: OrderService,
    private _taxonomyService: TaxonomyService,
    private _redirectService: RedirectService,
    public dialog: MatDialog,
    private _router: Router,
    private _globalService: GlobalService,
    public drawerService: DrawerService,
    private _productService: ProductService,
    private _boxService: BoxService,
    private _linkService: LinkService
  ) {

  }
  _env = environment;

  dateNextWeek = this._orderService.assumedDeliverydate;

  shownBox: Box;
  shownProduct: Product;
  orderlines: FormArray;
  order: FormGroup;
  taxonomyNode: TaxonomyNode;

  ordersideNavOpened = false;

  error_taxonomy = false;

  @ViewChild('detailsdrawer', { static: true }) public detailsdrawer;;
  @ViewChild('sidedrawercontent', { static: true }) public sidedrawercontent;

  currentUrl: string;

  offCanvas: NgbOffcanvasRef;
  offCanvasOpen = false;

  detailsDrawer: NgbOffcanvasRef;
  detailsdrawerOpen = false;

  private unsubscribe = new Subject<void>();
  orderRecipeCount$ = new BehaviorSubject<number>(0);

  get countrycode(): Countrycode {
    return this._orderService.countrycode.value
  }

  ngOnInit() {

    this._linkService.setStaticpageSeoData('singleorder.metatitle', 'singleorder.metadescription', '/assets/images/ekomenu_webshop.webp', 'singleorder.organicwebshop_url')

    this.drawerService.setDrawer(Drawerposition.bottom, this.detailsdrawer, 'webshop-details-drawer', 'h-[80vh] md:max-w-[90%] mx-auto overflow-auto');
    this.drawerService.setDrawer(Drawerposition.end, this.sidedrawercontent, 'webshop-drawer');

    this.orderlines = this._orderService.orderlines;
    this.order = this._orderService.order;
    this.orderRecipeCount$ = this._orderService.singleorderRecipeCount;

    this.getCurrentUrlState();

    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getCurrentUrlState();
    })
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getCurrentUrlState();
    })

    this._globalService.addToDatalayer('virtualPageLoaded')
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this._linkService.removeLinkTag('rel="alternate"')
    this._linkService.removeLinkTag('rel="canonical"')
    this._linkService.removeMetaData();
  }

  getCurrentUrlState() {
   
    const params = this.activatedRoute.snapshot.queryParams;
   
    if (!params.webshop ) {
      this._taxonomyService.getTaxonomy(environment.webshoptaxonomyid).subscribe(next => {
        this._taxonomyService.getTaxonomynode(next.entrynode).subscribe(
          next => {
            //makes sure that the urlparam is only applied when in the webshop
            if (this.activatedRoute.children.length == 0)
            this._router.navigate(
              [],
              {
                relativeTo: this.activatedRoute,
                queryParams: { webshop: next.id },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });

            this._redirectService.getEntityUrl(Entitytype.TAXONOMY, next.id, next.redirectid).then(value => next['taxonomyurl'] = value);
            this.taxonomyNode = next;

          }, error => {
            this.error_taxonomy = true;
          }
        );

      })
    }

    // use this ID i.s.o the SEO urls please
    if (params.product) {
      this._productService.getProduct(params.product).subscribe(result => {
        if (!this.detailsdrawerOpen) 
          this.toggleDetailsdrawer(result, null);
      });
    }

    if (params.box) {
      this._boxService.getBox(params.box).subscribe(result => {
        if (!this.detailsdrawerOpen) 
          this.toggleDetailsdrawer(null, result);
      });
    }

  }

  toggleDrawer() {
    if (!this.offCanvasOpen) {
      this.offCanvasOpen = true;
      this.offCanvas = this.drawerService.toggle('webshop-drawer');
      this.offCanvas.dismissed.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.offCanvasOpen = false;
      })
    } else {
      this.offCanvasOpen = false;
      this.offCanvas.dismiss();
    }
  }

  toggleDetailsdrawer(product: Product = null, box: Box = null) {
    if (product)
      this.shownProduct = product;
    if (box)
      this.shownBox = box;

    if (!this.detailsdrawerOpen && (box || product)) {
      this.detailsdrawerOpen = true;

      this._router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: { product: product?.id || null, box: box?.id || null },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

      this.detailsDrawer = this.drawerService.toggle('webshop-details-drawer');
      this.detailsDrawer.result.then(
        (result) => {
          this.detailsdrawerOpen = false;
          this.detailsdrawerDismissed()
          //console.log(`Closed with: ${result}`);
        },
        (reason) => {
          this.detailsdrawerOpen = false;
          this.detailsdrawerDismissed()
          //console.log(`Dismissed ${reason}`);
        },
      );
    } else {
      this.detailsdrawerOpen = false;
      if (this.detailsDrawer)
        this.detailsDrawer.dismiss();
    }
  }
  detailsdrawerDismissed() {
    setTimeout(() => {
      this.shownBox = null;
      this.shownProduct = null;
    }, 500);

    this._router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { product: null, box: null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }

  addOrderline(orderline: Orderline, product: Product = null, box: Box = null) {
    this._orderService.createNewOrderline(product, box, null, orderline.persons, orderline.quantity, false, false, true, orderline.onetimebox, Flowtype.SINGLEORDER)
  }
  changeProductSelectionQty(index: number = null, value: number, product: Product = null, box: Box = null) {
    const min = 1;
    
    if (value < min) {
      if(!index)
        index = this._orderService.getOrderlineIndex(product, box, null, false, Flowtype.SINGLEORDER)
      this._orderService.removeOrderlineByIndex(index);
      return;
    }

    if (index) {
      this._orderService.changeOrderlineByIndex(index, Flowtype.SINGLEORDER, null, value)
    } else {
      this._orderService.changeOrderlineByContent(product, box, null, Flowtype.SINGLEORDER, null, value, false)
    }
  }
  changeBoxPersonQty(index: number = null, value: number, box: Box = null) {

    if (index !== null) {
      this._orderService.changeOrderlineByIndex(index, Flowtype.SINGLEORDER, value, null)
    } else {
      this._orderService.changeOrderlineByContent(null, box, null, Flowtype.SINGLEORDER, value, null, false)
    }

  }





}
