export class Popup{
    constructor(
        public _uid: string,
        public _editable:any,
        public title: any,
        public size: ModalSize,
        public bgcolor:string,
        public sections: Section[],
        public closeconfig: PopupCloseConfig,
        public audience: StoryblokAudience
    ){}
}
export enum PopupCloseConfig{
    ALL = "ALL",
    CLOSEBUTTON = "CLOSEBUTTON",
    DISMISSABLE = "DISMISSABLE"
}
export enum StoryblokAudience{
    EVERYONE = "EVERYONE",
    CUSTOMERS = "CUSTOMERS",
    NON_CUSTOMERS = "NON_CUSTOMERS"
}

export enum ModalSize{
    SMALL= "sm",
    MEDIUM = "md",
    LARGE = "lg",
    EXTRALARGE ="xl"
}

export class Content{
    constructor(
        public _uid: string,
        public _editable:any,
        public sections: Section[],
        public component:string,

    ){}
}

export class Section{
    constructor (
        public _uid: string,
        public _editable:string,
        public body: any[],
        public title: any,
        public subtitle:any,
        public width: string,
        public layout: string,
        public bgcolor: string,
        public rounded: boolean,
        public blocks: any[],
        public anchor: string,
        public sticker:any,
        public component: string,
        public condensed: boolean,
        public main_image: any,
        public main_image_bgcolor: string,
        public main_image_contain: boolean
    ){}
}

export class Bar{

    constructor(
        public bgcolor: string,
        public text_color:string,
        public sticky: boolean,
        public fullwidth: boolean,
        public body: any,
        public audience: StoryblokAudience,
        public show_from_minutes_from_first_visit: number,
        public show_to_minuts_from_first_visit: number

    ){}
}

export class Slider{
    constructor (
        public caption:string,
        public expand_on_desktop:boolean,
        public fixed:boolean,
        public navigation_type: string,
        public slide_type: string,
        public no_of_slides: string,
        public show_numbers: boolean,
        public title: any,
        public slides: Slide[]

    ){}
}
export class Slide{
    constructor(
        public bg_color:string,
        public text_color:string,
        public blocks: any[],
        public hide_number:boolean,
        public main_image: any,
        public main_image_as_background: boolean,
        public title: any
    ){
    }
}

export class Card{
    constructor(
        public body: any,
        public title: any,
        public video: any,
        public vimeo_video: any,
        public blocks: any[],
        public layout: string,
        public flip_layout:boolean,
        public bgcolor: string,
        public sticker: any,
        public main_image: any,
        public text_color: string,
        public jumpy_image: boolean,
        public image_bgcolor:string,
        public auto_play_video: boolean,
        public main_imagecontain:boolean
    ){}
}

export class Stack{
    constructor(
        public layout: string,
        public flip_layout:boolean,
        public bgcolor: string,
        public single_block: any[],
        public stack_blocks: any[]
    ){}
}

export class Imageonly{
    constructor(
        public main_image:any,
        public title: any,
        public subtitle: any,
        public CTA: any[]
    ){}
}
