
<!-- navigation buttons on the sides -->
@if(!isMobile && slider.navigation_type === 'side_arrows'){
  <div class="flex flex-row justify-between absolute top-[46%] select-none" [ngClass]="[slider.slide_type === 'banner'? 'w-[84%] left-[8%]': 'w-full']">
    <div class="swiper-button-prev rounded-full bg-e-dark-green text-e-reg-green flex flex-row justify-center items-center">
      <img class="h-4 w-7 rotate-180" alt="next slide" src="./assets/images/icons/chevron_reg_green.svg" />
    </div>
    <div class="swiper-button-next rounded-full bg-e-dark-green text-e-reg-green flex flex-row justify-center items-center mr-0">
      <img class="h-4 w-7" alt="previous slide" src="./assets/images/icons/chevron_reg_green.svg" />
    </div>    
  </div>
}


<div class="container-default p-0 md:p-[25px] flex flex-col md:flex-row justify-center">
  <!-- This is a fixed slide, undouble later on -->
   @if(!!fixedBlock){
    <ng-container *ngTemplateOutlet="slideContent; context:{slide: fixedBlock, index:0, fixed:true}"></ng-container>
   }
  <swiper-container class="max-w-full" #swiperRef init="false">
    <swiper-slide *ngFor="let slide of slider.slides; let i = index">
      <ng-container *ngTemplateOutlet="slideContent; context:{slide: slide, index:i}"></ng-container>
    </swiper-slide>
  </swiper-container>  


</div>

  <!-- If we need navigation buttons -->
@if(!isMobile && slider.navigation_type === 'arrows'){
<div class="flex flex-row justify-center items-center relative bottom-0 select-none">
  <div class="swiper-button-prev flex flex-row justify-center items-center">
    <img class="h-4 w-7 rotate-180" alt="next slide" src="./assets/images/icons/arrow_right.svg" />
  </div>
  <div class="swiper-button-next flex flex-row justify-center items-center">
    <img class="h-4 w-7" alt="previous slide" src="./assets/images/icons/arrow_right.svg" />
  </div>    
</div>
}

<p *ngIf="slider?.caption" class="md:text-right">{{ slider.caption }}</p>

<ng-template #slideContent let-slide="slide" let-index="index" let-fixed="fixed">
  @if(!!slide.main_image?.filename && slide.main_image_as_background ){
    <div class="">
      <img [alt]="get_alt(slide.main_image)" class="mx-auto aspect-auto rounded-lg w-72" [src]="get_src(slide.main_image, null, 320)" loading="lazy" /> 
    </div>
  }
  <div class="flex flex-col px-1 md:px-10 rounded-lg" 
    [ngClass]="[
      (!!slide?.bg_color && !slide.main_image_as_background ? 'bg-' + slide.bg_color : '')
      ,(!!slide.main_image_as_background? 'justify-end absolute inset-0': '' )
      ,(slider.slide_type === 'banner' ? '' :'py-[30px]' )
      ,fixed? 'items-center justify-center md:mr-5 md:min-w-[400px] md:max-w-[400px]': ' h-full'
  ]">
      @if(!!slide.main_image?.filename && !slide.main_image_as_background ){
        <img [alt]="get_alt(slide.main_image)" class="inline-block mx-auto aspect-auto h-[125px] my-[15px]" [src]="get_src(slide.main_image, null, 125)" loading="lazy" />        
      }
    
    <div class="flex flex-row justify-center items-center text-center">
      
      <div 
        *ngIf="slider.show_numbers && !slide.hide_number" 
        [ngClass]="{'absolute top-5 left-0': !isMobile}"
        class="flex items-center justify-center min-h-8 min-w-8 rounded-full bg-e-dark-green text-e-reg-green mr-5">
        <span class="font-serif pt-1">{{ index + 1 }}</span>
      </div>
      
      <div *ngIf="slide?.title" [ngClass]="'text-' + slide?.text_color" [innerHTML]="slide['title_rendered']"></div>

      <ng-container *ngFor="let block of slide.blocks">
        @switch (block.component) {
          @case('card'){
            <app-cms-card class="w-full" [removeMargin]="slider.slide_type ==='banner'" [card]="block"></app-cms-card>
          }@case ('CTA') {
            <app-cms-button [button]="block"></app-cms-button>
          }

        }
        
       
      </ng-container>
    </div>
  </div>
</ng-template>