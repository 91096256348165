@if(!!button.URL){
<a [ngClass]="get_class(button.type)" [target]="button.URL?.target ? button.URL.target : '_self'" [href]="get_href(button.URL)">
    @switch (button.type) {
        @case ('BIG') {
            <div [innerHtml]="renderedTitle"></div>
            <img class="inline rotate-[-90deg] h-[30px] mb-[25px]" alt="arrow right" src="./assets/images/icons/arrow_down.svg" />
        }
        @case ('BIG_DARKGREEN') {
            <div class="duration-100 transition-transform hover:scale-105">
                <div class="font-serif" [innerHtml]="renderedTitle"></div>
                <div class="flex flex-row justify-between mt-[25px]" *ngIf="button.subtitle">
                    <small class="font-serif text-sm">{{ button.subtitle }}</small>
                    <img class="h-5 w-7 " src="./assets/images/icons/arrow_right_green.svg" alt="arrow right" />
                </div>
            </div>
        }
        @case ('ORANGE') {
            <div [innerHtml]="renderedTitle"></div>
            <i class="my-auto bi bi-chevron-right"></i>
        }
        @default {
            <div [innerHtml]="renderedTitle"></div>
        }
    }
    
</a>
}