import { Component, Input } from '@angular/core';
import { Imageonly } from 'src/_model/storyblock';
import { CmsService } from 'src/_service/CmsService';
import { CmsButtonComponent } from "../cms-button/cms-button.component";

@Component({
  selector: 'app-cms-image-only',
  standalone: true,
  templateUrl: './cms-image-only.component.html',
  styleUrl: './cms-image-only.component.scss',
  imports: [CmsButtonComponent]
})
export class CmsImageOnlyComponent {

  imageonlyTitle: any;

  private _imageonly: Imageonly
  @Input() set imageonly(value: Imageonly){
    this._imageonly = value
    if(value.title)
        this.imageonlyTitle = this._cmsService.render_rt(value.title)
  }
  get imageonly(){
    return this._imageonly
  }

  @Input() imageWidth=400;

  constructor(
    private _cmsService: CmsService

  ){}

  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }
}
